import { sendUtagEvent } from '../../core/analytics/utag_events'
import on from '../../utils/events_delegation'

const BLOCK_SELECTOR = '.js-main-header'

export function initializeUtagEvents() {
  document.querySelectorAll(`${BLOCK_SELECTOR} .utag-ib-logo`).forEach(element => {
    element.addEventListener('click', function () {
      sendUtagEvent({
        data: {
          event_name: 'logo',
          event_cat: 'logo',
          event_act: 'click logo iberostar',
          event_lbl: '',
          event_purpose: 'information',
          event_structure: 'header',
        },
      })
    })
  })
  on({
    eventName: 'click',
    selector: '.utag-header-menu-offers .utag-see-offer',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'offers',
          event_cat: 'offers',
          event_act: 'view offers conditions',
          event_structure: 'header',
          event_purpose: 'information',
        },
      })
    },
  })
}
