import { getLazyData } from '../../utils/get_lazy_data';

let data_audio = {
  audio_tag: null,
  audio_link: null,
  hotel_id: null,
  block_id: null,
  locale_path: null
}

function init() {
  if (document.querySelector('body').dataset.pageType != 'hotel' && !document.querySelector('audio')) return;

  let locale = $("html").attr("lang");
  data_audio.locale_path = (locale == 'es' ? "" : "/" + locale);

  let audio_links = document.querySelectorAll('.audio-link');
  audio_links.forEach((audio_link) => {
    audio_link.addEventListener('click', function (e) {
      e.preventDefault();
      data_audio.audio_link = audio_link;
      data_audio.audio_tag = audio_link.classList.contains('header-audio-link') ? document.querySelector('.header-audio') : document.querySelector('.masonry-audio');
      data_audio.hotel_id = $(this).data('hotel-id');
      data_audio.block_id = $(this).data('block-id');
      togglePlay();
    });
  });
  
}

function togglePlay() {
  data_audio.audio_link.classList.contains('audio-activated') ? stopAudio() : playAudio();
}

function playAudio() {
  if (!data_audio.audio_tag.classList.contains('loaded')) {
    generateAudio();
  } else {
    data_audio.audio_tag.play();
    document.querySelectorAll('.audio-link').forEach(function(_audio_link) {
      _audio_link.classList.add('audio-activated');
    });
  }
}

function generateAudio () {
  let url = `${data_audio.locale_path}/ajax_contents/get_audio_data?hotel_id=${data_audio.hotel_id}&block_id=${data_audio.block_id}`;
  getLazyData(url, function (data) {
    data_audio.audio_tag.setAttribute('src', data.src);
    data_audio.audio_tag.load();
    data_audio.audio_tag.loop = true;
    
    const playPromise = data_audio.audio_tag.play();
    if (playPromise !== undefined) {
      playPromise.then(() => {
        data_audio.audio_tag.classList.add('loaded');
        document.querySelectorAll('.audio-link').forEach(function(_audio_link) {
          _audio_link.classList.add('audio-activated');
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, 'json');
}


function stopAudio() {
  document.querySelectorAll('audio').forEach(function(audio_tag) {
    audio_tag.pause();
    audio_tag.currentTime = 0;
  });
  document.querySelectorAll('.audio-activated').forEach(function(_audio_link) {
    _audio_link.classList.remove('audio-activated');
  });
}

init();