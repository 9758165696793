import '../../vendors/layer_handler_ecc/layer_handler_eec';

$(document).on('click', '.js-tabs-nav li a', function (e) {
  e.preventDefault();

  let $el = $(this).parent('li');
  let $target = $($el.attr('data-tab'));

  changeTab($el, $target);
})

  .on('click', '.js-link-nav', function (e) {
    e.preventDefault();

    let $el = $(this);
    let data_tab = $el.attr('data-tab');
    let $target = $(data_tab);
    let $active_tab = $el.closest('.js-tabs-parent').find('.js-tabs-nav li[data-tab="' + data_tab + '"]');

    changeTab($active_tab, $target);
  })

  .on('click', '.js-tabs-actions li', function (e) {
    e.preventDefault();

    let $el = $(this);
    let $wrapper = $el.closest('.js-tabs');
    let klass = $el.attr('data-klass');

    $el.addClass('active').siblings().removeClass('active');
    $wrapper.attr('class', 'js-tabs ' + klass);

    // Si tiene sliders los reinicializamos
    $wrapper.find('.swiper-container').each(function () {
      $(this).data('swiper').update(true);
    });
  });

/**
 * Pone a activa la pestaña seleccionada y su contenido
 * @param {element} $active_tab
 * @param {element} $active_target
 */
function changeTab($active_tab, $active_target) {
  $active_tab.addClass('active')
    .siblings().removeClass('active');

  $active_target.addClass('active')
    .siblings().removeClass('active');

  // Si tiene sliders los reinicializamos
  $active_target.find('.swiper-container:visible').each(function () {
    $(this).data('swiper').update();
  });

  LayerHandlerEec.pushVisibleProducts('.destination-tabs-list .js-tab.active .js-tab.active .card-container .call-to-action a[data-ecc-product]');
}
