import '../../pages/offers_index/offers_index.scss';

$(document).on('click', '#offers-drop-down a', function (event) {
  let $this = $(this);
  let text = $this.text();

  $('#offers-drop-down').hide();
  $this.closest('.actions').find('.l-down').removeClass('active')
    .text(text);
});
