import on from '../../utils/events_delegation'

on({
  eventName: 'click',
  selector: `.js-open-chat`,
  handler(e) {
    e.preventDefault()
    try {
      window.lanzo_chat()
    } catch (err) {}
  },
})
