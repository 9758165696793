import Hls from 'hls.js'

import inViewport from '../utils/inViewport'

const HLS_LOADED_CLASS = 'is-hls-loaded'

function init(hlsVideos, config = {}) {
  const autoplay = config.autoplay !== undefined ? config.autoPlay : true
  const loop = config.loop !== undefined ? config.loop : true
  const playsWhenEnterViewport = config.playsWhenEnterViewport !== undefined ? config.playsWhenEnterViewport : true

  hlsVideos.forEach(video => {
    if (video.classList.contains(HLS_LOADED_CLASS)) return

    const videoSrc = video.dataset.url ? video.dataset.url : ''

    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(videoSrc)
      hls.attachMedia(video)
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.setAttribute('src', videoSrc)
    }

    video.classList.add(HLS_LOADED_CLASS)

    video.muted = true
    video.autoplay = autoplay
    video.loop = loop
    video.playsInline = autoplay

    if (playsWhenEnterViewport) {
      inViewport(video, element => {
        if (element.isIntersecting) {
          ;['canplaythrough', 'ended'].forEach(event => {
            video.addEventListener(event, () => {
              const timeout = event === 'ended' ? 10 : 0
              setTimeout(() => {
                video
                  .play()
                  .then(() => {
                    // console.log(`Video played on event ${event}`)
                  })
                  .catch(error => {
                    // console.log(`Error calling play() on video on event${event}`, error)
                  })
              }, timeout)
            })
          })
        }
      })
    }
  })
}

export function loadHlsVideo(baseSelector, config) {
  const videoElements = document.querySelectorAll(`${baseSelector} .hls-video`)

  if (videoElements.length) {
    init(videoElements, config)
  }
}

export function loadHlsVideoByElem(elem, config) {
  const videoElements = elem.querySelectorAll('.hls-video')

  if (videoElements.length) {
    init(videoElements, config)
  }
}
