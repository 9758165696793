import { sendUtagEvent } from '../../core/analytics/utag_events'
import { getCleanedString } from '../../utils/utilities'

export function initializeUtagEvents(element) {
  sendUtagEvent({
    data: {
      event_name: 'more_information_module',
      event_cat: 'more information',
      event_act: getCleanedString(element.dataset.blockSection),
      event_lbl: getCleanedString(element.dataset.blockTitle),
      event_purpose: 'inspiration',
      event_structure: 'body',
    },
  })
}
