import Modal from '../../shared/modal/modal'

const MODAL_SELECTOR = 'external-url-modal'

function showExternalUrlModal(url) {
  console.log(`%cENTRANDO EN SHOW!!`, 'color:yellow')

  const modalElement = document.getElementById(MODAL_SELECTOR)
  Modal.show(MODAL_SELECTOR, {
    onShow: () => {
      ScrollLock.on()

      const acceptButton = modalElement.querySelector('.js-external-url-accept-button')
      acceptButton.addEventListener('click', () => {
        Modal.close()
        window.open(url, '_blank')
        accept()
      }, { once: true })
      const rejectButton = modalElement.querySelector('.js-external-url-reject-button')

      rejectButton.addEventListener('click', () => {
        Modal.close()
      }, { once: true })
    },
    onClose: () => {
      ScrollLock.off()
    },
    awaitCloseAnimation: true,
  })
}

function init() {  
  const externalUrlLinkArray = document.querySelectorAll('a[data-external-url="true"]');
  externalUrlLinkArray.forEach(externalUrlLink => {
    externalUrlLink.addEventListener('click', e => {
      e.preventDefault()
      const url = externalUrlLink.getAttribute('href')
      showExternalUrlModal(url)
    })
  })
}

init()
