import { sendUtagEvent } from '../../core/analytics/utag_events'
import on from '../../utils/events_delegation'

export function initializeUtagEvents() {
  on({
    eventName: 'click',
    selector: '.utag-video-modal',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'multimedia',
          event_cat: 'videos',
          event_act: 'play',
          event_lbl: 'special landing header',
          event_purpose: 'information',
          event_structure: 'body',
        },
      })
    },
  })
}
