import '../../elements/pro_link/pro_link'

import { initTestRebHeader } from '../../utils/test_reb_header'

import MainHeader from './reb/header'
import { initializeUtagEvents } from './header.analytics'

function initHeader() {
  if (initTestRebHeader) {
    // const rebHeader = document.querySelector('.js-reb-header')
    // rebHeader.classList.remove('hidden')

    MainHeader.init()
  }
}

initHeader()

document.addEventListener('DOMContentLoaded', function () {
  initializeUtagEvents()
})
