import _max from 'lodash/max';
import './social_login.scss';

$(function () {
  let $social_login = $('.social-login');

  if (!$social_login.length) return false;

  let a = [];

  $social_login.find('li').each(function (index, element) {
    a.push($(element).find('a > span').width());
  });

  $social_login.find('li').find('a > span').width(_max(a) + 1);
});

$(document).on('click', 'a[data-social-login-check]', function (e) {
  let $el = $(this);
  let check_id = $el.data('socialLoginCheck');
  let $check = $('#' + check_id);

  if (!$check.is(':checked')) {
    e.preventDefault();
    $check.next('label').addClass('error');
  } else {
    $check.next('label').removeClass('error');
  }
}).on('change', 'input#social_login_accept', function (e) {
  let $el = $(this);
  let $block = $el.closest('.block-form');
  let is_checked = $el.is(':checked');
  $el.next('label').toggleClass('error', !is_checked);
  $block.find('.formikation.parsley-errors').toggleClass('hidden', is_checked);
});
