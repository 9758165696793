/*
  Util methods
  - IB.segment.get() : returns String
  - IB.segment.has() : returns boolean
*/

let segment
const element_selector = 'body'

function init() {
  let new_segment
  const $el = $(element_selector).first()

  if ($el.length && $el.is('[class*="seg-"]')) {
    const classes = $el.attr('class')
    new_segment = classes.substr(classes.match(/seg-*/).index + 4).split(' ')[0]
    IB.segment.set(new_segment)
  }
}

function set(segment) {
  this.segment = segment
}

function get() {
  return this.segment
}

function has() {
  return typeof this.segment !== 'undefined' && this.segment !== 'undefined' && this.segment !== ''
}

window.IB.segment = {
  set,
  get,
  has,
  init,
}

$(function () {
  IB.segment.init()
})
