// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { openGallery } from '../../../blocks/hotel_gallery/hotel_gallery'
import { FASTBOOKING_EVENTS } from '../../../shared/fastbooking/constants'
import fastbooking from '../../../shared/fastbooking/ibh_fastbooking'
import { currentPageType } from '../../../utils/page_type_detector'
import { initTestRebHeader } from '../../../utils/test_reb_header'

dayjs.extend(customParseFormat)

const lang = document.documentElement.getAttribute('data-js-lang')
const format = document.querySelector('html').dataset.dateFormat

/**
 * Main selectors
 */
const mainRebHeader = document.querySelector('.js-reb-header')
const headerPanel = mainRebHeader?.querySelector('.js-reb-header-panel') // Panel
const mainHeader = document.querySelector('.js-main-header')

/**
 * Selector for the menu toggle
 */
const menuToggle = document.querySelector('.js-vue-menu-toggle')

/**
 * Navbar
 */
const navBar = mainRebHeader?.querySelector('.js-reb-header-content')
/**
 * Placeholder to move fastbooking to the header
 */
const fastbookingInputFake = navBar?.querySelector('.js-fb-fake-input')

// List of pages where the test has to be started but in a different way.
export const EXCEPTIONS_REB_TEST = ['destination', 'destination-index', 'offer', 'you do you']

function moveFastBookingSticky() {
  const fastbooking = document.querySelector('.ibh-fastbooking')
  const fastbookingContainer = document.querySelector('.js-ibh-fastbooking-container')
  const fastbookingTabs = fastbookingContainer.querySelector('.js-ibh-fastbooking-tabs')
  const panelHeaderTabsContainer = headerPanel?.querySelector('.js-reb-header-tabs')
  const panelBody = headerPanel?.querySelector('.js-reb-header-panel-body')
  panelHeaderTabsContainer.appendChild(fastbookingTabs)
  panelBody.appendChild(fastbookingContainer)
  if (fastbookingContainer.classList.contains('is-hidden')) {
    fastbookingContainer.classList.remove('is-hidden')
  }
  if (!fastbooking.classList.contains('test-reb-header')) {
    fastbooking.classList.add('test-reb-header')
  }
}

function restoreFastBookingSticky() {
  const fastBookingWrapper = document.querySelector('#ibh-fastbooking .wrapper')
  const fastbooking = document.querySelector('.ibh-fastbooking')
  fastBookingWrapper?.appendChild(fastbooking)
  if (fastbooking?.classList.contains('js-test-reb-header')) {
    fastbooking.classList.remove('js-test-reb-header')
  }
}

function openPanel() {
  headerPanel.classList.remove('is-hidden')
  mainRebHeader.querySelector('.js-reb-header-hotel-nav')?.classList.add('is-hidden')
  if (mainRebHeader.offsetWidth > 0 && mainRebHeader.offsetHeight > 0) {
    // If header is visible, move fastbooking to the header
    moveFastBookingSticky()
  }
}

function initClickOpenPanel() {
  const fakeInput = navBar?.querySelector('.js-reb-header-fake .js-fb-fake-input')
  const button = navBar?.querySelector('.js-reb-header-fake .js-fb-button')

  ;[button, fakeInput].forEach(item => {
    item.addEventListener('click', e => {
      e.preventDefault()
      openPanel()
    })
  })
}

function closePanel() {
  headerPanel.classList.add('is-hidden')
  mainRebHeader.querySelector('.js-reb-header-hotel-nav')?.classList.remove('is-hidden')
  restoreFastBookingSticky()
}

function initClickClosePanel() {
  const iconClosePanel = headerPanel?.querySelector('.close-panel')
  iconClosePanel.addEventListener('click', closePanel)
}

function placeholderLastSearch() {
  // Capture event
  document.addEventListener(FASTBOOKING_EVENTS.STATE_UPDATED, () => {
    if (fastbooking.state.place_to_go) {
      if (fastbooking.state.dates) {
        const checkIn = dayjs(fastbooking.state?.dates?.check_in, format).format('D MMM') // D-M numeric format
        // Check if there is no Check_out. If there is, add separator
        const checkOut = fastbooking.state.dates.check_out
          ? `- ${dayjs(fastbooking.state?.dates?.check_out, format).format('D MMM')}`
          : '' // D-M numeric format
        fastbookingInputFake.innerHTML = `${fastbooking.state.place_to_go.name} <span></span> ${checkIn} ${checkOut}`
      } else {
        fastbookingInputFake.innerHTML = `${fastbooking.state.place_to_go.name}`
      }
    } else {
      fastbookingInputFake.textContent = fastbookingInputFake.getAttribute('data-initial-message')
    }
  })
}

function toggleMenu() {
  const menu = mainRebHeader?.querySelector('.js-reb-header-menu')
  const fakeInputHeader = navBar?.querySelector('.js-reb-header-fake')

  menuToggle.classList.toggle('close')
  menu.classList.toggle('is-hidden')
  fakeInputHeader.classList.toggle('is-hidden')
  ScrollLock.toggle()
}

function showContent() {
  moveFastBookingSticky()
}

function hideContent() {
  closePanel()
}

function copyInternalNav(internalNav) {
  const hotelNav = document.querySelector('.js-reb-header-hotel-nav')
  const fakeItems = hotelNav.querySelectorAll('.menu_item')

  internalNav.classList.add('internal-nav__hidden')

  internalNav.querySelector('.js-internal-nav-list').prepend(...fakeItems)
  fakeItems.forEach(item => {
    item.classList.remove('hidden')
  })

  hotelNav.append(internalNav)
  setTimeout(() => {
    internalNav.classList.remove('internal-nav__hidden')
  }, 100)
}

function initOpenGalleryButton() {
  mainRebHeader.querySelector('.js-open-hotel-gallery')?.addEventListener('click', e => {
    e.preventDefault()
    openGallery()
  })
}

function showStickyHeader() {
  if (!mainRebHeader.classList.contains('sticky')) {
    mainRebHeader.classList.add('sticky')
  }
}

function hideStickyHeader() {
  if (mainRebHeader.classList.contains('sticky')) {
    mainRebHeader.classList.remove('sticky')
  }
}

function scrollHome() {
  if (currentPageType.isHome()) {
    document.addEventListener('scroll', () => {
      const scroll = window.scrollY
      const header = document.querySelector('.home-megabanner-slider__slider')
      const headerHeight = header?.offsetHeight ?? 0
      const headerBottom = headerHeight - scroll
      const headerBottomLimit = 0
      if (headerBottom <= headerBottomLimit) {
        showStickyHeader()
      } else {
        hideStickyHeader()
        closePanel()
      }
    })
  }
}

function init() {
  if (!mainRebHeader) return

  if (initTestRebHeader) {
    mainRebHeader.classList.remove('hidden')
    mainHeader?.remove()

    import('../../../vue/main_nav/reb/vue_main_nav').then(mainNav => {
      mainNav.default.init()
    })

    scrollHome()

    initClickOpenPanel()
    initClickClosePanel()

    import(`dayjs/locale/${lang}.js`).then(() => {
      dayjs.locale(lang)
      placeholderLastSearch()
    })

    menuToggle.addEventListener('click', e => {
      e.preventDefault()
      toggleMenu()
    })

    // initOpenGalleryButton()
    // fastbookingInputFake.textContent =
    //   fastbooking.state.place_to_go?.name ?? fastbookingInputFake.getAttribute('data-initial-message')
  } else {
    mainRebHeader?.remove()
  }
}

const MainHeader = {
  init,
  showContent,
  hideContent,
  openPanel,
  copyInternalNav,
}

export default MainHeader
