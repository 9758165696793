import { sendUtagEvent } from '../../core/analytics/utag_events'
import { currentDevice } from '../../core/utils/currentDevice'
import on from '../../utils/events_delegation'

/**
 * Initializes event tracking for utag events.
 * On desktop, the event is triggered by a `mouseover` with a debounce delay of 5 seconds.
 * If the user moves the mouse out and back in, the timer resets and the event can fire again.
 */
export function initializeUtagEvents() {
  const handlerFunction = function () {
    const eventAct = this.closest('.utag-sustainable-info').dataset.utagEventLbl
    const eventLbl = this.closest('[data-utag-event-lbl]').dataset.utagEventLbl
    sendUtagEvent({
      data: {
        event_name: 'sustainability_module',
        event_cat: 'wave of change module',
        event_act: eventAct,
        event_lbl: eventLbl,
        event_structure: 'body',
        event_purpose: 'inspiration',
      },
    })
  }

  const handlerFunctionMoreInfoClick = function () {
    const eventAct = this.closest('.utag-sustainable-info').dataset.utagEventLbl
    sendUtagEvent({
      data: {
        event_name: 'sustainability_module',
        event_cat: 'wave of change module',
        event_act: eventAct,
        event_lbl: 'more information',
        event_structure: 'body',
        event_purpose: 'inspiration',
      },
    })
  }

  if (currentDevice.isDesktop) {
    on({
      eventName: 'mouseover',
      selector: `.utag-sustainable-info-item`,
      handler(event) {
        const timeout = setTimeout(() => handlerFunction.call(this, event), 5000)

        const clear = () => {
          clearTimeout(timeout)
          this.removeEventListener('mouseout', clear) // Clean up the event listener
        }

        this.addEventListener('mouseout', clear)
      },
    })
  } else {
    on({
      eventName: 'click',
      selector: `.utag-sustainable-info-item`,
      handler: handlerFunction,
    })
  }

  on({
    eventName: 'click',
    selector: `.utag-sustainable-more-info`,
    handler: handlerFunctionMoreInfoClick,
  })
}
