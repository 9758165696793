(function(global) {
  var LayerHandlerEec = {};
  global.LayerHandlerEec = LayerHandlerEec;

  LayerHandlerEec.init = function(gtm_id) {
    //Bindeamos los clicks de productos y promos, y las búsquedas desde fastbooking.
    this.bindEvents();
    //Mandamos las impresiones de los productos y las promociones de algunos módulos. Se juega con los selectores para identificar exactamente qué se manda
    //Algunos módulos mandarán impresiones también al avanzar la tab o el carrusel
    this.pushVisiblePromotions(".home-static-banner a[data-ecc-promo]");

    this.pushVisiblePromotions(".offers-index-list .card-container .call-to-action a[data-ecc-promo]");
    this.pushVisibleProducts(".offers-index-list .card-container .call-to-action a[data-ecc-product]");
    this.pushVisiblePromotions(".offers-list .card-container .call-to-action a[data-ecc-promo]");
    this.pushVisibleProducts(".offers-list .card-container .call-to-action a[data-ecc-product]");

    this.pushVisibleProducts(".destination-tabs-list .js-tab.active .js-tab.active .card-container .call-to-action a[data-ecc-product]");
    this.pushVisibleProducts(".hotel-list .card-container .call-to-action a[data-ecc-product]");

    if ($('.masonry-modules').length && IB.currentDevice === 'mobile') {
      //Nada aquí, lo hacemos en el masonry_modules.js, masonry_offers, etc.
    }else{
      this.pushVisiblePromotions(".masonry-modules div[class*='masonry-link'] [data-ecc-promo]");
      this.pushVisibleProducts(".masonry-modules div[class*='masonry-link'] [data-ecc-product]");
      this.pushVisibleProducts(".masonry-modules div[class*='masonry-offer'] [data-ecc-product]");
      this.pushVisiblePromotions(".offers-in-a-row [data-ecc-promo]");
      this.pushVisibleProducts(".offers-in-a-row [data-ecc-product]");
      this.pushVisiblePromotions(".multi-offers-slider [data-ecc-promo]");
      this.pushVisibleProducts(".multi-offers-slider [data-ecc-product]");

      //this.pushVisibleProducts(".near-hotels-slider .card-container a[data-ecc-product]:visible");
    }
  };

  LayerHandlerEec.bindEvents = function() {
    this.bindClicks();
    this.bindSearchs();
  };

  LayerHandlerEec.pushVisiblePromotions = function(selector) {
    promos = [];
    $(selector).each(function(){
      data = LayerHandlerEec.promoBaseData(this);
      if(data !== null) {
        promos.push(data);
      }
    });
    if (promos.length > 0){
      data = {"ecommerce": { "promoView": { "promotions": promos } } };
      this.pushData(data);
    }
  };

    LayerHandlerEec.pushVisibleProducts = function(selector) {
    products = [];
    currencyCode = "";
    is_fastbooking_result_page = (window.location.pathname.indexOf('/bookings') != -1 || window.location.pathname.indexOf('/reservas') != -1);
    is_still_working = $('#watch-job').length > 0;
    $(selector).each(function(){
      data = LayerHandlerEec.productBaseData(this);
      if(data !== null) {
        currencyCode = data["currency"];
        delete data["currency"];
        delete data["cart"];
        if (is_fastbooking_result_page && is_still_working) {
          data["dimension194"] = "Buscando precios";
        }
        products.push(data);
      }
    });
    if (products.length > 0){
      data = {"ecommerce": { "currencyCode": currencyCode, "impressions": products } };
      if (is_fastbooking_result_page && !is_still_working) {
        data["event"] = "loadProductDetails";
      }
      this.pushData(data);
    }
  };

  LayerHandlerEec.bindClicks = function() {
    document.body.addEventListener('click', function(event) {
      var element = event.target,
          data;
      data = this.promoClickData(element);
      this.pushData(data);
      data = this.productClickData(element);
      this.pushData(data);
    }.bind(this));
  }

  LayerHandlerEec.bindSearchs = function() {
    document.body.addEventListener('submit', function(event) {
      var element = event.target;

      data = this.searchBaseData(element);
      //Rellenamos el resto de datos del bloque y de la página
      if (data !== undefined){
        scr = $(element).closest("div").find("script[id*=ee-block]")
        if (scr.length > 0){
          block_data = JSON.parse($(scr).html());
          data["dimension190"] = block_data["title"];
          data["dimension193"] = block_data["type"];
        }
        data["dimension196"] = this.getPageData()["subcategory"];
        data = {"event": "addToCart", "ecommerce": { "add": { "products": [data] } } } ;
        this.pushData(data);
      }
    }.bind(this));
  }

  LayerHandlerEec.promoClickData = function(element) {
    data = this.promoBaseData(element);
    if (data !== null) {
      data = {"event": "promotionClick", "ecommerce": { "promoClick": { "promotions": [data] } } };
    }
    return data;
  }

  LayerHandlerEec.promoBaseData = function(element) {
    data = this.getElementData(element, 'data-ecc-promo');
    if (data !== null) {
      data = JSON.parse(data);
      block_data = this.getBlockData(data["block"]);
      delete data["block"];
      if (data["size"] !== undefined) {
        switch (data["size"]) {
          case 'masonry-link1x1':
            data["creative"] = "280x280";
            break;
          case 'masonry-link2x1':
            data["creative"] = "570x280";
            break;
          case 'masonry-link2x2':
            data["creative"] = "570x570";
            break;
        }
        delete data["size"];
      }else{
        data["creative"] = block_data["type"];
      }

      data["moduleTitleProm"] = block_data["title"];
      data["moduleTypeProm"] = block_data["type"];
      data["contentTypeProm"] = data["category"];
      delete data["category"];
      data["position"] = this.getPageData(data["block"])["category"];
    }
    return data;
  }

  LayerHandlerEec.productClickData = function(element) {
    data = this.productBaseData(element);
    if (data !== null) {
      if (data["cart"] == true){
        currencyCode = data["currency"];
        delete data["cart"];
        delete data["currency"];
        delete data["hotel"];
        data = {"event": "addToCart", "ecommerce": { "currencyCode": currencyCode, "add": { "products": [data] } } } ;
      }else
        if(data["hotel"] == true){
          delete data["hotel"];
          data = {"event": "viewProductDetails", "ecommerce": { "detail": { "actionField": { "list": data["list"] }, "products": [data] } } };
        }
        else{
          data = {"ecommerce": { "detail": { "actionField": { "list": data["list"] }, "products": [data] } } };
        }
    }
    return data;
  }

  LayerHandlerEec.productBaseData = function(element) {
    var data = this.getElementData(element, 'data-ecc-product')
    if (data !== null) {
      data = JSON.parse(data);
      hotel_data = IB.hotels_data.check() ? LayerHandlerEec.recoverHotelDataFromJson("h" + data["id"]) : IB.hotels_data.get(function(){ LayerHandlerEec.recoverHotelDataFromJson("h" + data["id"])});
      if (hotel_data){
        data["name"] = hotel_data["name"];
        data["id"] = hotel_data["id"];
        data["category"] = hotel_data["category"];
        data["hotel"] = true;
      }
      block_data = this.getBlockData(data["block"]);
      delete data["block"];
      data["dimension190"] = block_data["title"];
      data["dimension193"] = block_data["type"];
      if (data["price"] !== null && data["price"] != "0"){
        data["dimension194"] = "Dispo";
      }else{
        data["dimension194"] = "NoDispo";
      }

      page_data = this.getPageData();
      data["list"] = page_data["category"];
      data["dimension196"] = page_data["subcategory"];

      delete data["size"];
    }
    return data;
  }

  LayerHandlerEec.searchBaseData = function(element) {
    var hotel_id, data;
    //Si vamos a saltar a CRS (buscamos un hotel y buscamos con fechas), buscamos ese hotel en el JSON y rellenamos los datos
    if ($(element).find("[name*=check_in_date]").val() == "" || $(element).find("[name*=check_out_date]").val() == ""){
      return data;
    }
    if ($(element).find("[name*=place_to_go]").length > 0) {
      hotel_id = $(element).find("[name*=place_to_go]").val();
      if (!hotel_id.match("^h")) {
        hotel_id = LayerHandlerEec.recoverHotelFromPlaceToGo(hotel_id);
      }
    }else{
      hotel_id = "h" + $(element).find("[name*=hotel_id]").val();
    }
    if (hotel_id) {
      data = (IB.hotels_data.check() ? LayerHandlerEec.recoverHotelDataFromJson(hotel_id) : IB.hotels_data.get(function(){LayerHandlerEec.recoverHotelDataFromJson(hotel_id)}));
    }
    return data;
  }

  LayerHandlerEec.recoverHotelFromPlaceToGo = function(id) {
    if (!id.match("^d")){
      return null;
    }
    //Cogemos el destino actual y todos los destinos hijos
    var hotels_data = JSON.parse(sessionStorage.getItem('fastbooking_data'));
    if(hotels_data === undefined)
      return null;
    var destination_ids = current_ids = [id];
    do {
      var child_destinations = $.grep(hotels_data["destinations"][1], function( n, i ) {
        return $.inArray(n["data"]["parent_id"], current_ids) >= 0;
      });
      current_ids = [];
      for (var i = 0; i < child_destinations.length; i++) {
        destination_ids.push(child_destinations[i]["id"]);
        current_ids.push(child_destinations[i]["id"]);
      }
    } while (current_ids.length > 0);

    //Buscamos los hoteles de esos destinos, si solo es un hotel lo devolvemos
    var hotels = $.grep(hotels_data["hotels"][1], function( n, i ) {
      return $.inArray(n["data"]["destination"], destination_ids) >= 0;
    });
    if (hotels.length == 1) {
      return hotels[0]["id"];
    }else{
      return null;
    }
  }

  LayerHandlerEec.recoverHotelDataFromJson = function(hotel_id) {
    var data;
    var hotels_data = IB.hotels_data.getData();
    //Buscamos el hotel en el Json
    hotel = $.grep(hotels_data["hotels"][1], function( n, i ) {
      return n["id"] == hotel_id;
    });
    if (hotel.length > 0){
      var category = "";
      //Buscamos los destinos para rellenar el árbol hacia arriba
      var destination_id = hotel[0]["data"]["destination"];
      while (destination_id != "d"){
        destination = $.grep(hotels_data["destinations"][1], function( n, i ) {
          return n["id"] == destination_id;
        })
        category = "/" + destination[0]["data"]["title_es"] + category;
        destination_id = destination[0]["data"]["parent_id"];
      }

      data = {"name": hotel[0]["title"], "id": hotel[0]["data"]["code"], "category": category }
    }
    return data;
  }

  LayerHandlerEec.getElementData = function(element, attribute) {
    var data = null;
    do {
      data = element.getAttribute(attribute);
      element = element.parentElement;
    } while (element && element.parentElement && data === null);
    return data;
  }

  LayerHandlerEec.getBlockData = function(id) {
    var $eeBlock = $('#ee-block-' + id);

    if (id == 0 || $eeBlock.length < 1) {
      return {};
    } else {
      return JSON.parse($eeBlock.html());
    }
  }

  LayerHandlerEec.getPageData = function() {
    return JSON.parse($('#eec-page-data').html());
  }

  LayerHandlerEec.pushData = function(data) {
    if(data !== null) {
      dataLayer.push(data);
    }
  }
})(window);
