import { currentPageType } from '../../utils/page_type_detector'

function init() {
  if (currentPageType.isHotel() || currentPageType.isSpecialty()) {
    import(
      /* webpackChunkName: 'hotel_header' */
      `../../../src/blocks/hotel_header/hotel_header`
    ).catch(e => {
      console.warn(e)
    })

    // PY05355, Vue Hotel Header Lite
    import(
      /* webpackChunkName: 'vue_hotel_header_lite' */
      `../../../src/vue/hotel_header_lite/vue_hotel_header_lite`
    ).catch(e => {
      console.warn(e)
    })

    import(
      /* webpackChunkName: 'hotel_gallery_modal' */
      `../../../src/shared/hotel_gallery_modal/hotel_gallery_modal`
    )
      .then(blockJS => blockJS.init())
      .catch(e => {
        console.warn(e)
      })
  }
}

init()
