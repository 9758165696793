import '../../vendors/template_engine/template_engine'
import './opinion_widget.scss'

import { currentDevice } from '../../core/utils/currentDevice'
import Modal from '../../shared/modal/modal'
import { readCookie } from '../../utils/cookie_manager'

let template_options = ''
const isMobile = currentDevice.isMobile

$(document)
  .on('click', '.holidaycheck-widget', function (e) {
    e.preventDefault()
    const $block = $('#holidaycheck-comments-template')
    const hc_id = $(this).data('hc-id')
    $.fancybox.open($block, {
      autoSize: true,
      smallBtn: 'true',
      type: 'iframe',
      padding: 0,
      iframe: {
        preload: 'false',
      },
      beforeShow() {
        if (template_options === '' || template_options.holidaycheck_id !== hc_id) {
          template_options = { holidaycheck_id: hc_id }
          $.ajaxSetup({ cache: false })
          const hcSize = isMobile ? 'm' : 'l'
          const hc_script = `<script src='https://www.holidaycheck.de/widgets?step=code&type=code&options=hotel-1-{{this.holidaycheck_id}}-de_DE-${hcSize}-w-0-1-1-3-1' type='text/javascript'></script><div id='holidaycheck-widget-hotel-1-{{this.holidaycheck_id}}-de_DE-${hcSize}-w-0-1-1-3-1'><div id='holidaycheck_widget_placeholder-{{this.holidaycheck_id}}' style='width: 134px;padding: 8px;background:#0058a2;'><a href=''><img alt='Hotels mit Hotelbewertungen bei HolidayCheck' src='https://www.holidaycheck.de/widgets/logo_xlarge.png' border='0'></a></div></div>`
          $block.empty()
          $block.append(TemplateEngine(hc_script, template_options))
        }
      },
      afterShow() {
        // Añadimos un ancho a toda la pantalla cuando se trate de un dispositivo móvil. Sin anchura, no queda centrado en la pantalla.
        if (isMobile) document.querySelector('#holidaycheck-comments-template').style.width = '100%'
      },
    })
  })
  .on('click', '.tripadvisor-open-fancy', function (e) {
    e.preventDefault()
    if (!isMobile) {
      const $this = $(this)
      const opinionSrc = $this.attr('href')

      const dataTitleIframe = $this.closest('.tripadvisor-widget').data('title-iframe')

      Modal.showIframe({
        iframe: opinionSrc,
        modalClass: 'large-screens',
        titleIframe: dataTitleIframe,
        awaitCloseAnimation: true,
        onShow: () => {
          ScrollLock.on()
        },
        onClose: () => {
          ScrollLock.off()
        },
      })
    }
  })

function showWidget(widget_show, widget_hide) {
  $(widget_hide).addClass('hidden')
  $(widget_show).removeClass('hidden')

  if (widget_show === '.holidaycheck-widget') {
    let $widget
    let hc_id
    let hotel_id
    $.ajaxSetup({ cache: true }) // prevent timestamp as parameter in holidaycheck request
    $(widget_show).each(function (idx, widget) {
      $widget = $(widget)

      if ($widget.hasClass('loaded')) return

      if ($widget.data('hc-id') !== undefined) {
        hc_id = $widget.data('hc-id')
        hotel_id = `hotel-1-${hc_id}-de_DE-s-w-0-1-1-3-0`

        $widget
          .find('.widget')
          .append(
            `<script src='https://www.holidaycheck.de/widgets?step=code&type=code&options=${hotel_id}' type='text/javascript'></script><div id='holidaycheck-widget-${hotel_id}'><div id='holidaycheck_widget_placeholder-${hc_id}' style='width: 134px;padding: 8px;background:#0058a2;'><a href=''><img alt='Hotels mit Hotelbewertungen bei HolidayCheck' src='https://www.holidaycheck.de/widgets/logo_xlarge.png' border='0'></a></div></div>`
          )
        $widget.addClass('loaded')
      }
    })
  }
}

function init() {
  const country = readCookie('x-country')
  if (country === 'DE') {
    showWidget('.holidaycheck-widget', '.tripadvisor-widget')
  } else {
    showWidget('.tripadvisor-widget', '.holidaycheck-widget')
  }
}

window.IB.opinionsWidgets = {
  init,
}

IB.opinionsWidgets.init()
