import Modal from '../../shared/modal/modal'
import { readCookie, setCookie } from '../../utils/cookie_manager'
import { getParameterByName } from '../../utils/get_parameter_by_name'

const COOKIE_POPUP_FIA_VIEWED = 'popup_fia_viewed'

/**
 * Checks if the 'cp' parameter in the URL contains 'fia' followed by a country code.
 *
 * @returns {boolean} True if 'cp' matches 'fia' followed by a valid country code, otherwise false.
 */
function checkCountryInURL() {
  const cpValue = getParameterByName('cp')
  if (!cpValue) return false
  //comprobamos si existe la cookie fiacp y su valor coincide con el del cpValue en caso de existir eliminamos la cookie fiacp y devolvemos true
  if (readCookie('fiacp') === cpValue) {
    IB.cookieManager.deleteCookies(['fiacp'])
    return true
  }
  return false
}

function handlerPopupFia() {
  const isCountryInURL = checkCountryInURL()
  const isPopupFiaViewed = readCookie(COOKIE_POPUP_FIA_VIEWED)

  if (isCountryInURL && !isPopupFiaViewed) {
    Modal.show('modal-popup-fia', {
      onShow: () => {
        ScrollLock.on()
        setCookie(COOKIE_POPUP_FIA_VIEWED, window.location.href)
      },
      onClose: () => {
        ScrollLock.off()
      },
      awaitCloseAnimation: true,
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  handlerPopupFia()
})
