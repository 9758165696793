import '../session_page/session_page';
import '../../shared/js_tabs/js_tabs';

$('.b-login-registration-lightbox-wrapper .about-links--button').on('click', function () {
  $('.b-login-registration-lightbox-wrapper').scrollTo(0, { duration: 0 });
  $('.cabecera .square-tabs-menu').scrollTo(0, { duration: 0 });
});

// On login register lightbox opened (after ajax request success)
$('.js-user-options-link').on('ajax:success', function () {
  // Keyboard focus to allow accessibility
  // Must be delayed because lightbox content its not in DOM yet
  setTimeout(() => { document.querySelector('.js-login-registration-lightbox [data-fancybox-close]').focus(); }, 100);
});
