import YouTubePlayer from 'youtube-player'

import Modal from '../../shared/modal/modal'

import { initializeUtagEvents } from './special_landing_header_analytics'

const modalId = 'video-modal'
const contentVideo = '.video-special-landing'
let player

function stopVideo(videoElement) {
  videoElement.pause()
  videoElement.currentTime = 0
}
function initVideoYoutube(modal) {
  const idVideoYoutube = modal.querySelector('.js-video-youtube').dataset.videoYoutube
  const element = modal.querySelector('.js-video-youtube')

  player = YouTubePlayer(element, {
    videoId: idVideoYoutube,
    playerVars: {
      startSeconds: 0,
      rel: 0,
    },
  })

  player.playVideo()
}

function initVideoModal(modalId) {
  Modal.show(modalId, {
    onShow: modal => {
      ScrollLock.on()
      const video = modal.querySelector(contentVideo)

      if (!video.classList.contains('js-video-youtube')) {
        video.play()
      } else {
        initVideoYoutube(modal)
      }
    },
    onClose: modal => {
      const video = modal.querySelector(contentVideo)
      if (!video.classList.contains('js-video-youtube')) {
        stopVideo(video)
      } else {
        player.destroy()
      }

      ScrollLock.off()
    },
    awaitCloseAnimation: true,
    awaitOpenAnimation: true,
  })
}
function init() {
  const block = document.querySelector('.js-special-landing-header')

  if (!block) return

  const triggerModalVideo = block.querySelector('.js-video-modal')

  if (!triggerModalVideo) return

  triggerModalVideo.addEventListener('click', () => {
    initVideoModal(modalId)
  })
}

init()

initializeUtagEvents()
